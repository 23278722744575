// ==============================
// Custom style
// 自定义样式
// ==============================

// Hide author altogether
.post-author    {
    display: none !important;
}

// Hide post-info (post date) at bottom of posts
.post-info-mod  {
    display: none !important;
}

// Hide forward and backward suggested post navigation at bottom of posts
.post-nav   {
    display: none !important;
}

// Hide home and back navigation at bottom of posts
.back-home-nav  {
    display: none !important;
}

// Link styling - in addition to default theme variables
.page a {
    text-decoration: underline !important;
}

// Footer styling for creativelittledotcom attribution
.footer-creativelittle a    {
    font-weight: bold;
    text-decoration: none;
    opacity:0.75;
}
.footer-creativelittle a:hover span { 
    color: inherit !important;
}

// Newsletter subscription form styling
.newsletter-subscribe-form  {
    text-align: center;
}
.newsletter-subscribe-form a    { 
    text-decoration: underline; 
    box-shadow: none; 
}
.newsletter-subscribe-form input {
    font-size: 1.25em;
    margin: 0.5em;
    border-radius: 5px;
}
.newsletter-subscribe-form input[type=email] {
    padding: 4px 10px;
    width: 300px;
}
.newsletter-subscribe-form input:focus {
    background-color: #f7f7f7; 
 }




// Button styling - idea from https://www.joshwcomeau.com/animation/3d-button/
.pushable {
    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms;
}
.shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: hsl(211, 0%, 0% / 0.25);
    will-change: transform;
    transform: translateY(2px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.edge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(
      to left,
        hsl(211deg, 100%, 16%) 0%,
        hsl(211deg, 100%, 32%) 8%,
        hsl(211deg, 100%, 32%) 92%,
        hsl(211deg, 100%, 16%) 100%
    );
}
.front {
    display: block;
    position: relative;
    padding: 12px 42px;
    border-radius: 12px;
    font-size: 1.25rem;
    color: white;
    background: hsl(211, 100%, 47%);
    will-change: transform;
    transform: translateY(-4px);
    transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}
.pushable:hover {
    filter: brightness(110%);
}
.pushable:hover .front {
    transform: translateY(-6px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .front {
    transform: translateY(-2px);
    transition: transform 34ms;
}
.pushable:hover .shadow {
    transform: translateY(4px);
    transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
}
.pushable:active .shadow {
    transform: translateY(1px);
    transition: transform 34ms;
}
.pushable:focus:not(:focus-visible) {
    outline: none;
}

// Image and caption styling
figure {
    border: 1px solid black; 
    background-color: black;
    color: $global-caption-color;
    margin-bottom: 1rem !important;

    [theme=dark] & {
        color: $global-caption-color-dark;
    }
}

figcaption {
    margin-left: .5rem;
    margin-right: .5rem;
}

